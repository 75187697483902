import React, { useRef, useLayoutEffect } from "react";
import atomize from "@quarkly/atomize";

const EmbedHTMLJS = ({
	customHtml,
	customJs,
	children,
	...props
}) => {
	const ref = useRef(null);
	useLayoutEffect(() => {
		ref.current.innerHTML = customHtml;
		const script = document.createElement("script");
		script.innerHTML = customJs;
		ref.current.appendChild(script);
	}, []);
	return <div {...props} ref={ref} />;
};

export default atomize(EmbedHTMLJS)({
	name: "EmbedHTMLJS",
	normalize: true,
	mixins: true,
	propInfo: {
		customJs: {
			control: "text-area",
			type: "text",
			weight: 1
		},
		customHtml: {
			control: "text-area",
			type: "text",
			weight: 1
		}
	}
});